import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import userManager, { loadUserFromStorage } from './services/userService';
import AuthProvider from './utils/authProvider'

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const AppRenderer = () => {
  useEffect(() => {
    // fetch current user from cookies
    loadUserFromStorage(store);
  }, [])

  return (
    <Provider store={store}>
      <AuthProvider userManager={userManager} store={store}>
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </AuthProvider>
    </Provider>
  );
}

ReactDOM.render(
  <AppRenderer />,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
