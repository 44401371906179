/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'vi';
export const localeOptions = [
  { id: 'vi', name: 'Vietnamese', direction: 'ltr' },
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyCDS66rUvcfQnd0lltZ-t-w5t76WMrf--Y',
  authDomain: 'nppthaonguyen-85250.firebaseapp.com',
  databaseURL: 'https://nppthaonguyen-85250.firebaseio.com',
  projectId: 'nppthaonguyen-85250',
  storageBucket: 'nppthaonguyen-85250.appspot.com',
  messagingSenderId: '989287616803',
  appId: "1:989287616803:web:1ac96e94e9821e6717fc96"
};

export const searchPath = '#';
export const servicePath = 'https://api.coloredstrategies.com';


export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
