export const appUrl = {
    fe: process.env.REACT_APP_URL_FE,
    be: process.env.REACT_APP_URL_BE,
    noti: process.env.REACT_APP_URL_NOTI,
    id: process.env.REACT_APP_URL_ID
}

export const appSetting = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    env: process.env.REACT_APP_ENV
}