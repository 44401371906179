import { UserManager } from 'oidc-client';
import { storeUserError, storeUser } from '../actions/authActions'
import { appUrl, appSetting } from '../appconfig';

const config = {
  authority: appUrl.id,
  client_id: appSetting.clientId,
  redirect_uri: `${appUrl.fe}/user/signin-oidc`,
  response_type: "id_token token",
  scope: "openid profile role nppviet",
  post_logout_redirect_uri: `${appUrl.fe}/user/signout-oidc`,
};

const userManager = new UserManager(config)

export async function loadUserFromStorage(store) {
  try {
    let user = await userManager.getUser()
    if (!user) { return store.dispatch(storeUserError() ) }
    store.dispatch(storeUser(user))
  } catch (e) {
    console.error(`User not found: ${e}`)
    store.dispatch(storeUserError())
  }
}

export function signinRedirect() {
  return userManager.signinRedirect()
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export async function signoutRedirect() {
  let user = await userManager.getUser();
  userManager.clearStaleState()
  userManager.removeUser()  
  return userManager.signoutRedirect({ 'id_token_hint': user.id_token })
}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager