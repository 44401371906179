import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authReducer from './auth/authReducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser: authReducer,
});

export default reducers;
